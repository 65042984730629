* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

:root {
  /* Theming for Amplify UI */
  --amplify-primary-color: #EEA320;
  --amplify-primary-tint: #EEA320;
  --amplify-primary-shade: #EEA320;
  --amplify-font-family: Roboto, sans-serif;
}

amplify-authenticator {
  /* Allow room for the logo in the login form */
  --container-height: auto;
}

@media print {
  /* Use simple black/white when printing */
  * {
    color: black !important;
    background-color: transparent !important;
  }
}
